import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _763b5a69,
    name: "home___de"
  }, {
    path: "/en",
    component: _763b5a69,
    name: "home___en"
  }, {
    path: "/es",
    component: _763b5a69,
    name: "home___es"
  }, {
    path: "/de/cart",
    component: _1d513f9d,
    name: "cart___de"
  }, {
    path: "/de/checkout",
    component: _a301d77a,
    name: "checkout___de",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___de"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___de"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___de"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___de"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___de"
    }]
  }, {
    path: "/de/Cms",
    component: _740316af,
    name: "Cms___de"
  }, {
    path: "/de/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___de",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de"
    }, {
      path: "/de/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___de"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de"
    }]
  }, {
    path: "/de/Faq",
    component: _03c11c08,
    name: "Faq___de"
  }, {
    path: "/de/Home",
    component: _763b5a69,
    name: "Home___de"
  }, {
    path: "/de/Page",
    component: _731f1fce,
    name: "Page___de"
  }, {
    path: "/en/cart",
    component: _1d513f9d,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _a301d77a,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___en"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___en"
    }]
  }, {
    path: "/en/Cms",
    component: _740316af,
    name: "Cms___en"
  }, {
    path: "/en/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/Faq",
    component: _03c11c08,
    name: "Faq___en"
  }, {
    path: "/en/Home",
    component: _763b5a69,
    name: "Home___en"
  }, {
    path: "/en/Page",
    component: _731f1fce,
    name: "Page___en"
  }, {
    path: "/es/cart",
    component: _1d513f9d,
    name: "cart___es"
  }, {
    path: "/es/checkout",
    component: _a301d77a,
    name: "checkout___es",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___es"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___es"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___es"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___es"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___es"
    }]
  }, {
    path: "/es/Cms",
    component: _740316af,
    name: "Cms___es"
  }, {
    path: "/es/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___es",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___es"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___es"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___es"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___es"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___es"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___es"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___es"
    }, {
      path: "/es/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___es"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___es"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___es"
    }]
  }, {
    path: "/es/Faq",
    component: _03c11c08,
    name: "Faq___es"
  }, {
    path: "/es/Home",
    component: _763b5a69,
    name: "Home___es"
  }, {
    path: "/es/Page",
    component: _731f1fce,
    name: "Page___es"
  }, {
    path: "/de/:slug+",
    component: _731f1fce,
    name: "page___de"
  }, {
    path: "/en/:slug+",
    component: _731f1fce,
    name: "page___en"
  }, {
    path: "/es/:slug+",
    component: _731f1fce,
    name: "page___es"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
